import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImage from "../../components/gatsby-image";
import SocialProfile from "../../components/social-profile/social-profile";
import {
	IoLogoFacebook,
	IoLogoTwitter,
	IoLogoInstagram,
	IoLogoLinkedin,
} from "react-icons/io";
import {
	AboutWrapper,
	AboutImage,
	AboutPageTitle,
	AboutDetails,
	SocialProfiles,
} from "./style";

const SocialLinks = [
	{
		icon: <IoLogoFacebook />,
		url: "https://www.facebook.com/redqinc/",
		tooltip: "Facebook",
	},
	{
		icon: <IoLogoInstagram />,
		url: "https://www.instagram.com/redqinc/",
		tooltip: "Instagram",
	},
	{
		icon: <IoLogoTwitter />,
		url: "https://twitter.com/redqinc",
		tooltip: "Twitter",
	},
	{
		icon: <IoLogoLinkedin />,
		url: "https://www.linkedin.com/company/redqinc/",
		tooltip: "Linked In",
	},
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
	const Data = useStaticQuery(graphql`
		query {
			avatar: file(absolutePath: { regex: "/about.jpg/" }) {
				childImageSharp {
					gatsbyImageData(
						layout: FULL_WIDTH
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			site {
				siteMetadata {
					author
					about
				}
			}
		}
	`);

	return (
		<AboutWrapper>
			<AboutPageTitle>
				<h2>運営者情報</h2>
				<p>運営者 ギークライブラリー</p>
			</AboutPageTitle>

			{/* <AboutImage>
        <GatsbyImage src={Data.avatar.childImageSharp.gatsbyImageData} alt="about" />
      </AboutImage> */}

			<AboutDetails>
				{/* <h2>Hey there, what’s up?</h2> */}
				<p>
					当サイトは、アフィリエイトプログラムにより商品をご紹介致しております。アフィリエイトプログラムとは、商品及びサービスの提供元と業務提携を結び商品やサービスを紹介するインターネット上のシステムです。
					従いまして、当サイトの商品は当サイトが販売している訳ではありません。
					お客様ご要望の商品、お支払い等はリンク先の販売店と直接のお取引となりますので、
					特商法に基づく表記につきましてはリンク先をご確認頂けます様お願い致します。商品の価格商品の詳細
					消費税 送料 在庫数等の詳細は時として変わる場合も御座います。
					当サイトだけではなくリンク先のサイトもよくご確認頂けます様お願い致します。
					また、当サイトの掲載情報をご利用頂く場合には、お客様のご判断と責任におきましてご利用頂けます様お願い致します。当サイトでは、一切の責任を負いかねます事ご了承願います。
					尚、掲載商品に関するお問合せはリンク先に御座います企業宛までお願い致します。当サイト管理者側ではお答え致しかねます事、ご了承ください。
				</p>
				{/* <p>
					RedQ Team is a creative agency specializing in building
					scalable, high-performance web & mobile application. Our
					main concern is creating more value into the application so
					that can help our customers to grow their business.
				</p> */}

				{/* <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles> */}
			</AboutDetails>
		</AboutWrapper>
	);
};

export default About;
